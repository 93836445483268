import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

const S = {}

S.InstagramContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding-top: 87px;
  margin-top: -87px;

  margin: 3rem auto;
  max-width: ${props => props.theme.width.max};
`

S.Section = styled.div`
  display: ${props => (props.hiddenOnMobile ? 'none' : 'flex')};
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: flex;
  }
`

S.InstagramFeedContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 50%;
  }
`
S.ImageWrapper = styled.div`
  padding: 4px;

  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  .my-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
`

S.BigImage = styled(S.ImageWrapper)`
  display: none;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: block;
    width: 50%;
  }
`

S.SmallImage = styled(S.ImageWrapper)`
  width: 50%;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: ${props => props.width};
    display: ${props => (props.isHidden ? 'none' : 'block')};
  }
`

S.Button = styled.a`
  background: ${props => props.theme.color.brand};
  text-transform: uppercase;
  color: white;
  padding: 15px 30px;
  font-size: 14px;
  font-family: ${props => props.theme.fontPrimary};
  cursor: pointer;
  transition: all 0.3s;
  margin: 1rem auto;

  :hover {
    background: #a67941;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    /* font-size: 14px; */
    /* padding: 10px 50px; */
  }
`

const Image = ({ post, width, hidden }) => {
  return (
    <S.SmallImage width={width} isHidden={hidden}>
      <Img className="my-wrapper" fixed={post.localFile.childImageSharp.fixed} alt={post.caption} />
    </S.SmallImage>
  )
}

class InstagramFeed extends React.Component {
  constructor(props) {
    super(props)
    const data = this.props.data
    const { edges: unsortedPosts } = data.allInstaNode

    // Sort the posts decsendingly, where the most recent post showed first.
    const posts = unsortedPosts.sort((a, b) => b.node.timestamp - a.node.timestamp)

    this.state = {
      posts: posts,
      showingPosts: posts.slice(0, 9),
      postsCount: 9,
    }
  }

  viewMore = () => {
    let postCount = this.state.postsCount
    let newPostCount = postCount + 4
    this.setState({
      showingPosts: this.state.posts.slice(0, newPostCount),
      postsCount: newPostCount,
    })
  }

  render() {
    const showingPosts = [...this.state.showingPosts]
    const firstPost = showingPosts.shift()
    let nextFourPosts = []

    for (var i = 0; i < 4; i++) {
      let post = showingPosts.shift()
      if (!post) {
        break
      }
      nextFourPosts.push(post)
    }

    return (
      <S.InstagramContainer id="social">
        <S.Section hiddenOnMobile={true}>
          {firstPost && firstPost.node && (
            <S.BigImage>
              <Img
                className="my-wrapper"
                fixed={firstPost.node.localFile.childImageSharp.fixed}
                alt={firstPost.node.caption}
              />
            </S.BigImage>
          )}

          <S.InstagramFeedContainers>
            {firstPost && firstPost.node && (
              <Image post={firstPost.node} width="50%" hidden={true} />
            )}
            {nextFourPosts &&
              nextFourPosts.map(({ node: post }, index) => {
                if (post) {
                  return <Image key={index} post={post} width="50%" />
                }
                return null
              })}
          </S.InstagramFeedContainers>
        </S.Section>

        <S.Section>
          {firstPost && firstPost.node && <Image post={firstPost.node} width="50%" hidden={true} />}
          {nextFourPosts &&
            nextFourPosts.map(({ node: post }, index) => {
              if (post) {
                return <Image key={index} post={post} width="50%" hidden={true} />
              }
              return null
            })}
          {showingPosts &&
            showingPosts.map(({ node: post }, index) => {
              if (post) {
                return <Image key={index} post={post} width="25%" />
              }
              return null
            })}
        </S.Section>
        {this.state.postsCount < this.state.posts.length && (
          <S.Button onClick={this.viewMore}>View More</S.Button>
        )}
      </S.InstagramContainer>
    )
  }
}

InstagramFeed.propTypes = {
  data: PropTypes.shape({
    allInstaNode: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allInstaNode {
          totalCount
          edges {
            node {
              id
              caption
              timestamp
              localFile {
                childImageSharp {
                  fixed(cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <InstagramFeed id="social" data={data} count={count} />}
  />
)
