import { Flex } from './design-system'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.Wrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 87px;
  margin-top: -87px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    flex-direction: row;
  }
`

S.FlexHalf = styled.div`
  flex-basis: 50%;
`

S.FittedImg = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

S.Content = styled(S.FlexHalf)`
  color: #555;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
  z-index: 1;
  background-color: white;
`

S.ContentContainer = styled.div`
  max-width: 568px;
  font-family: ${props => props.theme.fontPrimary};
  font-size: 14px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 16px;
  }
`

S.SubHeading = styled.h3`
  font-family: ${props => props.theme.fontSerif};
  font-size: 20px;
  font-weight: 400;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 22px;
  }
`

S.Heading = styled.h2`
  font-family: ${props => props.theme.fontSerif};
  font-style: italic;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 2.5rem;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 32px;
  }
`

S.Description = styled.p`
  font-family: ${props => props.theme.fontPrimary};
  font-size: 14px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 16px;
  }
`

export default class About extends React.Component {
  render() {
    const { heading, subheading, descriptionOne, descriptionTwo, image } = this.props

    return (
      <S.Wrapper id="about">
        <S.Content>
          <S.ContentContainer>
            <S.SubHeading>{subheading}</S.SubHeading>
            <S.Heading>{heading}</S.Heading>
            <S.Description>{descriptionOne}</S.Description>
            <S.Description>{descriptionTwo}</S.Description>
          </S.ContentContainer>
        </S.Content>
        <S.FlexHalf>
          <S.FittedImg fluid={image.childImageSharp.fluid} alt="Barista" />
        </S.FlexHalf>
      </S.Wrapper>
    )
  }
}
