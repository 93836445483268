import React from 'react'
import styled from 'styled-components'

import { Flex } from './design-system'

const S = {}

S.Wrapper = styled(Flex)`
  padding: 0;
  padding-top: 60px;

  .shopify-buy__collection-products {
    text-align: right !important;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding: 60px 250px 0 250px;
  }
`

export default class ShopifyStore extends React.Component {
  componentDidMount() {
    let scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
    let script = document.createElement('script')
    script.async = true
    script.src = scriptURL
    ;(
      document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
    ).appendChild(script)
    script.onload = function() {
      let client = window.ShopifyBuy.buildClient({
        domain: 'whatsontap.myshopify.com',
        storefrontAccessToken: 'c3a808ba6047b19ade42caa51b801db8',
      })

      window.ShopifyBuy.UI.onReady(client).then(function(ui) {
        ui.createComponent('collection', {
          id: 77732085812,
          node: document.getElementById('collection-component-582380970d4'),
          moneyFormat: 'RM%7B%7Bamount%7D%7D%20MYR',
          options: {
            product: {
              buttonDestination: 'modal',
              variantId: 'all',
              contents: {
                imgWithCarousel: false,
                variantTitle: false,
                description: false,
                buttonWithQuantity: false,
                quantity: false,
              },
              text: {
                button: 'View Product',
              },
              styles: {
                product: {
                  'margin-top': '15px !important',
                  '@media (min-width: 601px)': {
                    'max-width': 'calc(25% - 20px)',
                    'margin-left': '20px',
                    'margin-bottom': '50px',
                  },
                  imgWrapper: {
                    position: 'relative',
                    height: '0',
                    'padding-top': 'calc(75% + 15px)',
                  },
                  img: {
                    height: 'calc(100% - 15px)',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                  },
                },
                button: {
                  'background-color': '${props => props.theme.color.brand}',
                  ':hover': {
                    'background-color': '#b28f63',
                  },
                  'border-radius': '0px',
                  ':focus': {
                    'background-color': '#b28f63',
                  },
                },
              },
            },
            cart: {
              contents: {
                button: true,
              },
              styles: {
                button: {
                  'background-color': '${props => props.theme.color.brand}',
                  ':hover': {
                    'background-color': '#b28f63',
                  },
                  'border-radius': '0px',
                  ':focus': {
                    'background-color': '#b28f63',
                  },
                },
                footer: {
                  'background-color': '#ffffff',
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                variantTitle: false,
                buttonWithQuantity: true,
                button: false,
                quantity: false,
              },
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0px',
                    'margin-bottom': '0px',
                  },
                },
                button: {
                  'background-color': '${props => props.theme.color.brand}',
                  ':hover': {
                    'background-color': '#b28f63',
                  },
                  'border-radius': '0px',
                  ':focus': {
                    'background-color': '#b28f63',
                  },
                },
              },
            },
            toggle: {
              styles: {
                toggle: {
                  'background-color': '${props => props.theme.color.brand}',
                  ':hover': {
                    'background-color': '#b28f63',
                  },
                  ':focus': {
                    'background-color': '#b28f63',
                  },
                },
              },
            },
            productSet: {
              styles: {
                products: {
                  '@media (min-width: 601px)': {
                    'margin-left': '-20px',
                    'text-align': 'left',
                  },
                },
              },
            },
          },
        })
      })
    }
  }

  render() {
    return <S.Wrapper id="collection-component-582380970d4" />
  }
}
