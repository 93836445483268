import { Flex } from './design-system'
import GoogleMap from './GoogleMap'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.Wrapper = styled(Flex)`
  flex-direction: column;
  border-bottom: 1px solid #eee;

  padding-top: 87px;
  margin-top: -87px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    /* mask-position */
    flex-direction: row;
  }
`

S.FlexHalf = styled(Flex)`
  width: 100%;
  font-family: ${props => props.theme.fontPrimary};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;

  p {
    font-size: 14px;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 50%;
    padding: 0;

    p {
      font-size: 16px;
    }
  }
`

S.Title = styled.div`
  font-size: ${props => (props.isPrimary ? '24px' : '16px')};
  font-weight: ${props => (props.isPrimary ? 400 : 600)};
  text-transform: uppercase;
  margin-bottom: ${props => (props.isPrimary ? '1.5rem' : 0)};
  letter-spacing: 1.5px;
`

S.Address = styled.address`
  font-style: normal;
  font-size: 14px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 16px;
  }
`

S.Contact = styled.div`
  margin-bottom: 1.5rem;

  p {
    margin: 0;
  }
  a {
    color: #01a1dd;
  }
  a:hover {
    color: #00709a;
  }
`

S.BusinessHour = styled.div`
  margin-bottom: 1.5rem;

  p {
    margin: 0;
  }
`

export default class Contact extends React.Component {
  render() {
    const {
      email,
      phoneNumber,
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      addressLineFour,
      businessHours,
    } = this.props

    return (
      <S.Wrapper id="contact">
        <GoogleMap />

        <S.FlexHalf>
          <div>
            {/* <S.Title isPrimary>Our Branch</S.Title> */}
            <S.Title>Address</S.Title>
            <a href="http://maps.google.com/maps?q=B-G-05, Plaza, Mont Kiara, 50480 Kuala Lumpur">
              <S.Address>
                {addressLineOne}
                <br />
                {addressLineTwo}
                <br />
                {addressLineThree}
                <br />
                {addressLineFour}
              </S.Address>
            </a>

            <S.Title>Phone Number</S.Title>
            <S.Contact>
              <a href={'tel:' + phoneNumber}>{phoneNumber}</a>
            </S.Contact>

            <S.Title>Email</S.Title>
            <S.Contact>
              <a href={'mailto:' + email}>{email}</a>
            </S.Contact>

            <S.Title>Business Hours</S.Title>
            <S.BusinessHour>
              {businessHours &&
                businessHours.map((businessHour, index) => (
                  <p>
                    {businessHour.day}: {businessHour.hour}
                  </p>
                ))}
            </S.BusinessHour>

            {/* <p>{ businessHours }</p> */}
          </div>
        </S.FlexHalf>
      </S.Wrapper>
    )
  }
}
