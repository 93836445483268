import { Flex, StyledLink } from './design-system'

import Img from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.Wrapper = styled(Flex)`
  border-bottom: 1px solid #eee;
  padding: 70px 10px;
  align-items: stretch;
  flex-wrap: wrap;

  padding-top: calc(87px + 70px);
  margin-top: -87px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    /* padding: 80px 32px; */
  }
`

S.A = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.black};
`

S.Link = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.color.black};
`

S.NewsItem = styled.div`
  width: 100%;
  padding: 1rem;
  flex-grow: 1;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    width: 50%;
  }

  @media (min-width: ${props => props.theme.breakpoint.wide}) {
    width: 25%;
  }
`

S.CardContent = styled.div`
  /* background-color: #f3f3f3; */
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  height: 100%;

  img {
    transition: all 0.8s !important;
  }

  &:hover {
    transform: translateY(-4px);

    img {
      transform: scale(1.15);
    }
  }
`

S.CardHeader = styled.div`
  height: 48vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    height: 24vw;
  }

  @media (min-width: ${props => props.theme.breakpoint.wide}) {
    height: 12vw;
  }
`

S.CardBody = styled.div`
  flex-grow: 1;
  /* padding: 1.5rem 1.5rem; */

  /* @media (min-width: ${props => props.theme.breakpoint.tablet}) {
  } */

  /* @media (min-width: ${props => props.theme.breakpoint.desktop}) {
  } */
`

S.BodyTitle = styled.h3`
  margin-top: 1em;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
`

S.BodyExcerpt = styled.p`
  font-family: ${props => props.theme.fontPrimary};
  font-size: 14px;
  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export default class NewsSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }
  render() {
    const { photoIndex, isOpen } = this.state
    const { news } = this.props
    return (
      <S.Wrapper id="news">
        {news &&
          news.map((post, index) => (
              <S.NewsItem key={index}>
                <S.Link to={post.node.fields.slug}>
                  <S.CardContent>
                    <S.CardHeader>
                      <Img fluid={post.node.frontmatter.image.childImageSharp.fluid} />
                    </S.CardHeader>
                    <S.CardBody>
                      <S.BodyTitle>{post.node.frontmatter.title}</S.BodyTitle>
                      <S.BodyExcerpt>{post.node.excerpt}</S.BodyExcerpt>
                    </S.CardBody>
                  </S.CardContent>
                </S.Link>
              </S.NewsItem>
            )
            // post.node.frontmatter.link ?
            // (
            //   <S.NewsItem key={index}>
            //     <S.A href={post.node.frontmatter.link}>
            //       <S.CardContent>
            //         <S.CardHeader>
            //           <Img fluid={post.node.frontmatter.image.childImageSharp.fluid} />
            //         </S.CardHeader>
            //         <S.CardBody>
            //           <S.BodyTitle>{post.node.frontmatter.title}</S.BodyTitle>
            //           <S.BodyExcerpt>{post.node.excerpt}</S.BodyExcerpt>
            //         </S.CardBody>
            //       </S.CardContent>
            //     </S.A>
            //   </S.NewsItem>
            // ) : (
            //   <S.NewsItem key={index}>
            //     <S.Link to={post.node.fields.slug}>
            //       <S.CardContent>
            //         <S.CardHeader>
            //           <Img fluid={post.node.frontmatter.image.childImageSharp.fluid} />
            //         </S.CardHeader>
            //         <S.CardBody>
            //           <S.BodyTitle>{post.node.frontmatter.title}</S.BodyTitle>
            //           <S.BodyExcerpt>{post.node.excerpt}</S.BodyExcerpt>
            //         </S.CardBody>
            //       </S.CardContent>
            //     </S.Link>
            //   </S.NewsItem>
            // )
          )}
      </S.Wrapper>
    )
  }
}
