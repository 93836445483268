import About from '../components/About'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import InstagramFeed from '../components/InstagramFeed'
import Layout from '../components/Layout'
import MenuSection from '../components/MenuSection'
import Navbar from '../components/Navbar'
import NewsSection from '../components/NewsSection'
import PropTypes from 'prop-types'
import React from 'react'
import ShopifyStore from '../components/ShopifyStore'

export const IndexPageTemplate = ({
  images,
  video,
  aboutHeading,
  aboutSubheading,
  aboutDescriptionOne,
  aboutDescriptionTwo,
  aboutImage,
  showInstagram,
  showStore,
  contactEmail,
  phoneNumber,
  addressLineOne,
  addressLineTwo,
  addressLineThree,
  addressLineFour,
  businessHours,
  menus,
  news,
}) => (
  <React.Fragment>
    <Navbar
      showInstagram={showInstagram}
      showStore={showStore}
      listenToScroll={true}
      isTop={true}
    />
    <Hero images={images} video={video} />
    <About
      heading={aboutHeading}
      subheading={aboutSubheading}
      descriptionOne={aboutDescriptionOne}
      descriptionTwo={aboutDescriptionTwo}
      image={aboutImage}
    />
    {showInstagram && <InstagramFeed />}
    <MenuSection menus={menus} />
    {showStore && <ShopifyStore />}
    <NewsSection news={news} />

    <Contact
      email={contactEmail}
      phoneNumber={phoneNumber}
      addressLineOne={addressLineOne}
      addressLineTwo={addressLineTwo}
      addressLineThree={addressLineThree}
      addressLineFour={addressLineFour}
      businessHours={businessHours}
    />
  </React.Fragment>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges } = data.allMarkdownRemark

  const menus = edges
    .map(edge => {
      let object = {
        title: edge.node.frontmatter.title,
        order: edge.node.frontmatter.order,
        items: edge.node.frontmatter.menuItems,
      }
      return object
    })
    .sort(
      (a, b) =>
        // Refer to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        a.order - b.order
    )

  return (
    <Layout>
      <IndexPageTemplate
        images={frontmatter.images}
        video={frontmatter.video}
        aboutHeading={frontmatter.aboutHeading}
        aboutSubheading={frontmatter.aboutSubheading}
        aboutDescriptionOne={frontmatter.aboutDescriptionOne}
        aboutDescriptionTwo={frontmatter.aboutDescriptionTwo}
        aboutImage={frontmatter.aboutImage}
        showInstagram={frontmatter.showInstagram}
        showStore={frontmatter.showStore}
        contactEmail={frontmatter.contactEmail}
        phoneNumber={frontmatter.phoneNumber}
        addressLineOne={frontmatter.addressLineOne}
        addressLineTwo={frontmatter.addressLineTwo}
        addressLineThree={frontmatter.addressLineThree}
        addressLineFour={frontmatter.addressLineFour}
        businessHours={frontmatter.businessHours}
        menus={menus}
        news={data.news.edges}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "menu" } } }) {
      edges {
        node {
          frontmatter {
            title
            order
            menuItems {
              description
              price
              title
            }
            type
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        images {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        video {
          publicURL
        }
        aboutHeading
        aboutSubheading
        aboutDescriptionOne
        aboutDescriptionTwo
        aboutImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        showInstagram
        showStore
        contactEmail
        phoneNumber
        addressLineOne
        addressLineTwo
        addressLineThree
        addressLineFour
        businessHours {
          day
          hour
        }
      }
    }
    news: allMarkdownRemark(filter: { frontmatter: { type: { eq: "news" } } }, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            subtitle
            link
            date
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
