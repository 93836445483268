import { Flex } from './design-system'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.Wrapper = styled(Flex)`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 70px 10px;

  && {
    padding-top: calc(87px);
    margin-top: calc(-87px + 6rem);
  }

  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding: 70px 0px;
  }
`

S.MenuWrapper = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  width: 60%;
  color: #555;

  h2 {
    font-family: ${props => props.theme.fontPrimary};
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 12px;
  }

  & + & {
    margin-top: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    justify-content: space-between;
    text-align: left;

    h2 {
      text-align: left;
      font-size: 20px;
      text-transform: uppercase;
    }

    & + & {
      margin-top: 60px;
    }
  }
`

S.MenuTitle = styled.h2`
  &&& {
    margin-bottom: calc(20px * 2);
  }
`

S.MenuItems = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 80%;
  }
`

S.MenuItemWrapper = styled.div`
  font-family: ${props => props.theme.fontPrimary};
  font-size: 16px;

  width: 100%;
  margin-bottom: calc(14px * 2);

  h3 {
    line-height: 16px;
    letter-spacing: 1.6px;
    font-size: 14px;
    font-weight: 400;
    /* margin-bottom: 4px; */
  }

  p {
    font-size: 16px;
    margin-bottom: 0;
    color: #777;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 50%;
    margin-bottom: 40px;

    h3 {
      font-size: 16px;
      margin-bottom: 0.75em;
    }

    p {
      font-size: 16px;
    }
  }
`

S.MenuItemTitle = styled.h3`
  &&& {
    margin-bottom: calc(14px * 1 / 1.618);
    line-height: 1.5;
  }
`

S.MenuItemPrice = styled.div`
  color: ${props => props.theme.color.brand};
  font-size: 14px;
  margin-bottom: calc(14px * 1 / 1.618);
`

S.MenuItemDesc = styled.div`
  color: #777;
  font-size: 14px;
  margin-bottom: calc(14px * 1 / 1.618);
  font-style: italic;
`

const Menu = ({ title, items }) => {
  return (
    <S.MenuWrapper>
      <S.MenuTitle>{title}</S.MenuTitle>
      <S.MenuItems>
        {items && items.map((item, index) => <MenuItem key={index} {...item} />)}
      </S.MenuItems>
    </S.MenuWrapper>
  )
}

const MenuItem = ({ title, description, price }) => {
  return (
    <S.MenuItemWrapper>
      <S.MenuItemTitle>{title}</S.MenuItemTitle>
      {price && <S.MenuItemPrice>{price}</S.MenuItemPrice>}
      {description && <S.MenuItemDesc>{description}</S.MenuItemDesc>}
    </S.MenuItemWrapper>
  )
}

export default class MenuSection extends React.Component {
  render() {
    const { menus } = this.props

    return (
      <S.Wrapper id="menu">
        {menus &&
          menus.map((menu, index) => <Menu key={index} title={menu.title} items={menu.items} />)}
      </S.Wrapper>
    )
  }
}
