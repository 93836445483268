import Img from 'gatsby-image'
import MediaQuery from 'react-responsive'
import React from 'react'
import ReactPlayer from 'react-player'
import Slider from 'react-slick'
import styled from 'styled-components'

const S = {}

S.Wrapper = styled.div`
  position: relative;
  width: 100%;
  object-fit: cover;
`
S.FittedImage = styled(Img)`
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: cover;
`

S.FittedVideo = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    right: 0;

    // height: auto !important;
    height: 100vh !important;
    min-height: 100%;
    min-width: 100%;

    video {
      display: block;
      height: auto !important;
      min-height: 100%;
      min-width: 100%;
      z-index: 0;
    }
  }
`

S.Overlay = styled.div`
  position: absolute;
  background-image: linear-gradient(#000, rgba(0, 0, 0, 0));
  opacity: 1;
  width: 100%;
  height: 20%;
  z-index: 10;
`

export default class Hero extends React.Component {
  render() {
    const { images, video } = this.props

    const settings = {
      dots: false,
      infinite: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <S.Wrapper id="gallery">
        <S.Overlay />

        <MediaQuery query="(min-width: 1024px)">
          <ReactPlayer
            wrapper={S.FittedVideo}
            width="100%"
            url={video.publicURL}
            muted
            playing
            loop
          />
        </MediaQuery>

        <MediaQuery query="(max-width: 1023px)">
          <Slider {...settings}>
            {images &&
              images.map(({ image }, index) => (
                <div key={index}>
                  <S.FittedImage fluid={image.childImageSharp.fluid} alt="What's on Tap store" />
                </div>
              ))}
          </Slider>
        </MediaQuery>
      </S.Wrapper>
    )
  }
}
