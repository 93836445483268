import React, { Component } from 'react'

import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'

const S = {}

S.Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  iframe {
    margin: 0;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    width: 50%;
  }
`

S.Marker = styled.div`
  position: absolute;
  background-color: #D0A473;
  text-align: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 25px;
  border-radius: 100%;
  transform: translate(-50%, -50%);




`

const mapOptions = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          hue: '#adadad',
        },
        {
          saturation: 10,
        },
        {
          lightness: 10,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'all',
      stylers: [
        {
          hue: '#adadad',
        },
        {
          saturation: 10,
        },
        {
          lightness: 10,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          hue: '#gggggg',
        },
        {
          saturation: 100,
        },
        {
          lightness: 50,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [
        {
          hue: '#a8f1ff',
        },
        {
          saturation: 50,
        },
        {
          lightness: 50,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'all',
      stylers: [
        {
          hue: '#ffffff',
        },
        {
          saturation: 100,
        },
        {
          lightness: 100,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          hue: '#ffffff',
        },
        {
          saturation: 100,
        },
        {
          lightness: 100,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          hue: '#adadad',
        },
        {
          saturation: 10,
        },
        {
          lightness: 10,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [
        {
          hue: '#adadad',
        },
        {
          saturation: 10,
        },
        {
          lightness: 10,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          hue: '#bbbbbb',
        },
        {
          saturation: -100,
        },
        {
          lightness: 26,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          hue: '#dddddd',
        },
        {
          saturation: -100,
        },
        {
          lightness: -3,
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
}

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 3.1654588,
      lng: 101.6513546,
    },
    zoom: 15,
  }

  render() {
    return (
      // Important! Always set the container height explicitly.
      <S.Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCSyNFU0DbVqmQRmuVvU6-XgYMehOvNbDA' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={mapOptions}
        >
          <S.Marker lat={3.1654588} lng={101.6513546} text="My Marker">We Are Here</S.Marker>
        </GoogleMapReact>
      </S.Wrapper>
    )
  }
}

export default GoogleMap
